<template>
  <div class="carousel">
    <div class="carousel__titles">
      <div
        v-for="(Title, TitleIndex) in Data.Titles"
        :key="TitleIndex"
        class="carousel__titles__item"
      >
        {{ $t(Title) }}
      </div>
    </div>
    <video
      poster="@/assets/Images/Views/Home/Carousel/Poster.png"
      @canplay="ResolveElement($event)"
      class="carousel__video"
      autoplay
      loop
      muted
    >
      <source
        :src="require('@/assets/Images/' + Data.Videos[Video])"
        type="video/mp4"
      />
    </video>
    <img
      @click="ScrollTo()"
      src="@/assets/Images/Global/ArrowF.svg"
      alt=""
      class="carousel__arrow"
    />
  </div>
</template>

<script>
export default {
  name: "Carousel",
  props: {
    Data: Object,
  },
  data() {
    return {
      Video: 0,
    };
  },
  methods: {
    ResolveElement(e) {
      var element;
      if (e.target == null || e.target == undefined) {
        element = e.path[0];
      } else {
        element = e.target;
      }
      element.parentNode.style.opacity = 1;
    },
    ScrollTo() {
      var products = document.getElementsByClassName("products")[0];
      products.scrollIntoView();
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel {
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  width: 100dvw;
  height: 100dvh;
  overflow: hidden;
  -webkit-transition: 0.35s ease-in-out opacity, 0.35s ease-in-out height;
  -moz-transition: 0.35s ease-in-out opacity, 0.35s ease-in-out height;
  -ms-transition: 0.35s ease-in-out opacity, 0.35s ease-in-out height;
  -o-transition: 0.35s ease-in-out opacity, 0.35s ease-in-out height;
  transition: 0.35s ease-in-out opacity, 0.35s ease-in-out height;
  &__titles {
    letter-spacing: -4px;
    font-weight: 300;
    font-size: 100px;
    color: white;
    position: absolute;
    text-align: center;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    text-shadow: 6px 6px 8px rgb(0 0 0 / 40%), 0px 8px 13px rgb(0 0 0 / 10%),
      0px 18px 23px rgb(0 0 0 / 10%);
    @media screen and (max-width: 950px) {
      font-size: 80px;
    }
    @media screen and (max-width: 650px) {
      left: 40%;
      font-size: 60px;
    }
    @media screen and (max-width: 500px) {
      width: 100%;
      left: 50%;
    }
    &__item {
      &:not(:last-child) {
        margin-top: -5px;
      }
      &:last-child {
        font-family: Jellyka;
        transform: rotate(350deg);
        margin-left: 195px;
        margin-top: -35px;
        font-size: 120px;
        width: 100%;
        letter-spacing: 2px;
        word-spacing: -12px;
        @media screen and (max-width: 950px) {
          font-size: 100px;
        }
        @media screen and (max-width: 650px) {
          font-size: 80px;
        }
        @media screen and (max-width: 500px) {
          transform: rotate(0deg);
          margin-left: revert;
          margin-top: -15px;
        }
      }
    }
  }
  &__video {
    opacity: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-transition: 0.35s ease-in-out opacity;
    -moz-transition: 0.35s ease-in-out opacity;
    -ms-transition: 0.35s ease-in-out opacity;
    -o-transition: 0.35s ease-in-out opacity;
    transition: 0.35s ease-in-out opacity;
  }
  &__arrow {
    cursor: pointer;
    filter: invert(100%);
    position: absolute;
    height: 80px;
    bottom: 0px;
    -webkit-transition: 0.35s ease-in-out bottom;
    -moz-transition: 0.35s ease-in-out bottom;
    -ms-transition: 0.35s ease-in-out bottom;
    -o-transition: 0.35s ease-in-out bottom;
    transition: 0.35s ease-in-out bottom;
    &:hover {
      bottom: -5px;
      -webkit-transition: 0.35s ease-in-out bottom;
      -moz-transition: 0.35s ease-in-out bottom;
      -ms-transition: 0.35s ease-in-out bottom;
      -o-transition: 0.35s ease-in-out bottom;
      transition: 0.35s ease-in-out bottom;
    }
  }
}
</style>
