<template>
  <div class="home">
    <Carousel :Data="Carousel" />
    <Products :Data="Products" />
  </div>
</template>

<script>
import Carousel from "@/components/Views/Home/Carousel.vue";
import Products from "@/components/Views/Home/Products.vue";

export default {
  name: "Home",
  components: {
    Carousel,
    Products,
  },
  data() {
    return {
      Carousel: {
        Titles: [
          "Views.Home.Carousel.Titles[0]",
          "Views.Home.Carousel.Titles[1]",
        ],
        Videos: ["Views/Home/Carousel/Office.mp4"],
      },
      Products: [
        {
          Title: "Views.Home.Products.Images.Title",
          Subtitle: "Views.Home.Products.Images.Subtitle",
          Claim: "Views.Home.Products.Images.Claim",
          Texts: [
            "Views.Home.Products.Images.Texts[0]",
            "Views.Home.Products.Images.Texts[1]",
          ],
          Image: "Views/Home/Products/Images.jpg",
          URL: "Images",
        },
        {
          Title: "Views.Home.Products.Selector.Title",
          Subtitle: "Views.Home.Products.Selector.Subtitle",
          Claim: "Views.Home.Products.Selector.Claim",
          Texts: [
            "Views.Home.Products.Selector.Texts[0]",
            "Views.Home.Products.Selector.Texts[1]",
          ],
          Image: "Views/Home/Products/Selector.jpg",
          URL: "Selector",
        },
        {
          Title: "Views.Home.Products.AugmentedReality.Title",
          Subtitle: "Views.Home.Products.AugmentedReality.Subtitle",
          Claim: "Views.Home.Products.AugmentedReality.Claim",
          Texts: ["Views.Home.Products.AugmentedReality.Texts[0]"],
          Image: "Views/Home/Products/AugmentedReality.jpg",
          URL: "AugmentedReality",
        },
        {
          Title: "Views.Home.Products.VirtualTour.Title",
          Subtitle: "Views.Home.Products.VirtualTour.Subtitle",
          Claim: "Views.Home.Products.VirtualTour.Claim",
          Texts: [
            "Views.Home.Products.VirtualTour.Texts[0]",
            "Views.Home.Products.VirtualTour.Texts[1]",
          ],
          Image: "Views/Home/Products/VirtualTour.jpg",
          URL: "VirtualTour",
        },
        {
          Title: "Views.Home.Products.Video.Title",
          Subtitle: "Views.Home.Products.Video.Subtitle",
          Claim: "Views.Home.Products.Video.Claim",
          Texts: ["Views.Home.Products.Video.Texts[0]"],
          Image: "Views/Home/Products/Video.jpg",
          URL: "Video",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
}
</style>
