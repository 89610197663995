<template>
  <div class="products">
    <div
      v-for="(Product, ProductIndex) in Data"
      :key="ProductIndex"
      class="products__item"
    >
      <div class="products__item__product">
        <div class="products__item__product__info">
          <div class="products__item__product__info__title langchange">
            <div data-aos="fade-up">
              {{ $t(Product.Title) }}
            </div>
          </div>
          <div class="products__item__product__info__subtitle langchange">
            <div data-aos="fade-up">
              {{ $t(Product.Subtitle) }}
            </div>
          </div>
          <div class="products__item__product__info__claim langchange">
            <div data-aos="fade-up">
              {{ $t(Product.Claim) }}
            </div>
          </div>
          <div class="products__item__product__info__texts">
            <div
              v-for="(Text, TextIndex) in Product.Texts"
              :key="TextIndex"
              class="products__item__product__info__texts__item langchange"
            >
              <div data-aos="fade-up">
                {{ $t(Text) }}
              </div>
            </div>
          </div>
          <div class="products__item__product__info__btn langchange">
            <div data-aos="fade-up" style="display: flex; align-items: center">
              <router-link
                class="products__item__product__info__btn__text"
                :to="Product.URL"
              >
                {{ $t("Literales.SeeMore") }}
              </router-link>
              <img
                @click="$router.push(Product.URL)"
                src="@/assets/Images/Global/ArrowG.svg"
                alt=""
                class="products__item__product__info__btn__image"
              />
            </div>
          </div>
        </div>
        <div
          class="products__item__product__miniatura skeleton"
          :data-aos="ProductIndex % 2 == 0 ? 'fade-up-left' : 'fade-up-right'"
        >
          <img
            @load="ResolveElement($event)"
            :src="require('@/assets/Images/' + Product.Image)"
            alt=""
            class="products__item__product__miniatura__image"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Products",
  props: {
    Data: Array,
  },
  methods: {
    ResolveElement(e) {
      var element;
      if (e.target == null || e.target == undefined) {
        element = e.path[0];
      } else {
        element = e.target;
      }
      element.parentNode.classList.remove("skeleton");
      element.style.opacity = 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.products {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%;
  background-image: url("@/assets/Images/Views/Home/Background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 150px 20px 150px 20px;
    background-color: rgba(241, 241, 241, 0.6);
    &__product {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      align-content: center;
      max-width: 1100px;
      &__info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-right: 50px;
        height: 100%;
        &__title {
          font-size: 20px;
          text-transform: uppercase;
          font-weight: 600;
          letter-spacing: 12px;
        }
        &__subtitle {
          font-size: 28px;
          font-weight: 300;
          margin-top: 25px;
        }
        &__claim {
          font-size: 15px;
          font-weight: 800;
          margin-top: 5px;
        }
        &__texts {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          font-size: 13px;
          font-weight: 400;
          line-height: 25px;
          margin-top: 45px;
          &__item {
            &:not(:first-child) {
              margin-top: 20px;
            }
          }
        }
        &__btn {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          align-content: center;
          margin-top: 55px;
          &__text {
            text-transform: uppercase;
            color: black;
            text-decoration: none;
            cursor: pointer;
            font-size: 13px;
            font-weight: 500;
            &:hover + .products__item__product__info__btn__image {
              filter: invert(100%);
              margin-left: 20px;
              border: 1px solid white;
              background-color: white;
              -webkit-transition: 0.35s ease-in-out all;
              -moz-transition: 0.35s ease-in-out all;
              -ms-transition: 0.35s ease-in-out all;
              -o-transition: 0.35s ease-in-out all;
              transition: 0.35s ease-in-out all;
            }
          }
          &__image {
            cursor: pointer;
            filter: invert(0%);
            height: 26px;
            width: 26px;
            padding: 5px;
            object-fit: contain;
            border: 1px solid black;
            border-radius: 100%;
            transform: rotate(-90deg);
            margin-left: 10px;
            background-color: rgba(241, 241, 241, 0.6);
            -webkit-transition: 0.35s ease-in-out all;
            -moz-transition: 0.35s ease-in-out all;
            -ms-transition: 0.35s ease-in-out all;
            -o-transition: 0.35s ease-in-out all;
            transition: 0.35s ease-in-out all;
            &:hover {
              filter: invert(100%);
              border: 1px solid white;
              background-color: white;
              -webkit-transition: 0.35s ease-in-out all;
              -moz-transition: 0.35s ease-in-out all;
              -ms-transition: 0.35s ease-in-out all;
              -o-transition: 0.35s ease-in-out all;
              transition: 0.35s ease-in-out all;
            }
          }
        }
      }
      &__miniatura {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 450px;
        min-width: 350px;
        background-color: transparent;
        -webkit-transition: 0.35s ease-in-out background-color;
        -moz-transition: 0.35s ease-in-out background-color;
        -ms-transition: 0.35s ease-in-out background-color;
        -o-transition: 0.35s ease-in-out background-color;
        transition: 0.35s ease-in-out background-color;
        &__image {
          opacity: 0;
          height: 450px;
          width: 350px;
          object-fit: cover;
          -webkit-transition: 0.35s ease-in-out opacity;
          -moz-transition: 0.35s ease-in-out opacity;
          -ms-transition: 0.35s ease-in-out opacity;
          -o-transition: 0.35s ease-in-out opacity;
          transition: 0.35s ease-in-out opacity;
        }
      }
    }
    &:nth-child(4) {
      & .products__item__product {
        &__info {
          width: 40%;
        }
        &__miniatura {
          width: 60%;
          &__image {
            width: 100%;
            object-fit: contain;
          }
        }
      }
    }
    &:nth-child(odd) {
      background-color: rgba(255, 255, 255, 0.3);
      & .products__item__product {
        flex-direction: row-reverse;
        &__info {
          padding-right: revert;
          padding-left: 50px;
          &__btn {
            &__text {
              &:hover + .products__item__product__info__btn__image {
                filter: invert(100%);
                margin-left: 20px;
                border: 1px solid white;
                background-color: white;
                -webkit-transition: 0.35s ease-in-out all;
                -moz-transition: 0.35s ease-in-out all;
                -ms-transition: 0.35s ease-in-out all;
                -o-transition: 0.35s ease-in-out all;
                transition: 0.35s ease-in-out all;
              }
            }
            &__image {
              background-color: rgba(255, 255, 255, 0.3);
              &:hover {
                filter: invert(100%);
                border: 1px solid white;
                background-color: white;
                -webkit-transition: 0.35s ease-in-out all;
                -moz-transition: 0.35s ease-in-out all;
                -ms-transition: 0.35s ease-in-out all;
                -o-transition: 0.35s ease-in-out all;
                transition: 0.35s ease-in-out all;
              }
            }
          }
        }
      }
      @media screen and (max-width: 800px) {
        padding: 50px 20px 50px 20px;
        & .products__item__product {
          flex-direction: column;
          align-items: center;
          &__info {
            padding-left: revert;
            height: 100%;
            margin-bottom: 50px;
          }
          &__miniatura {
            width: 100%;
            min-height: revert;
            min-width: revert;
            &__image {
              width: 100%;
              max-height: 450px;
              height: 100%;
            }
          }
        }
      }
    }
    @media screen and (max-width: 800px) {
      padding: 50px 20px 50px 20px;
      &__product {
        flex-direction: column;
        align-items: center;
        &__info {
          padding-right: revert;
          height: 100%;
          margin-bottom: 50px;
        }
        &__miniatura {
          width: 100%;
          min-height: revert;
          min-width: revert;
          &__image {
            width: 100%;
            max-height: 450px;
            height: 100%;
          }
        }
      }
      &:nth-child(4) {
        & .products__item__product {
          &__info {
            width: 100%;
          }
          &__miniatura {
            width: 100%;
            &__image {
              width: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
}
</style>
