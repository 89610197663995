<template>
  <div class="apploader">
    <video
      @canplay="ResolveElement($event)"
      class="apploader__video"
      autoplay
      loop
      muted
    >
      <source src="@/assets/Images/Static/AppLoader.webm" type="video/mp4" />
    </video>
  </div>
</template>

<script>
export default {
  name: "AppLoader",
  methods: {
    ResolveElement(e) {
      var element;
      if (e.target == null || e.target == undefined) {
        element = e.path[0];
      } else {
        element = e.target;
      }
      element.style.opacity = 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.apploader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100dvw;
  height: 100dvh;
  background-color: black;
  overflow: hidden;
  -webkit-transition: 0.35s ease-in-out height;
  -moz-transition: 0.35s ease-in-out height;
  -ms-transition: 0.35s ease-in-out height;
  -o-transition: 0.35s ease-in-out height;
  transition: 0.35s ease-in-out height;
  &__video {
    opacity: 0;
    width: 40%;
    min-width: 250px;
    max-width: 450px;
    height: 100px;
    object-fit: contain;
    -webkit-transition: 0.35s ease-in-out opacity;
    -moz-transition: 0.35s ease-in-out opacity;
    -ms-transition: 0.35s ease-in-out opacity;
    -o-transition: 0.35s ease-in-out opacity;
    transition: 0.35s ease-in-out opacity;
  }
}
</style>
