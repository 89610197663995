<template>
  <transition-group name="fade">
    <AppLoader v-if="Aplicacion.Loading" key="AppLoader" />
    <div class="app" v-else key="App">
      <Header />
      <transition name="fade" @enter="Refresh()" @leave="Refresh()">
        <router-view />
      </transition>
      <Scroller />
      <Modal />
      <Footer class="app__footer" />
    </div>
  </transition-group>
</template>

<script>
import AOS from "aos";

import AppLoader from "@/components/Static/AppLoader.vue";
import Header from "@/components/Static/Header.vue";
import Footer from "@/components/Static/Footer.vue";
import Scroller from "@/components/Static/Scroller.vue";
import Modal from "@/components/Static/Modal.vue";

export default {
  name: "App",
  components: {
    AppLoader,
    Header,
    Footer,
    Scroller,
    Modal,
  },
  created() {
    window.addEventListener("resize", this.Resize);
  },
  destroyed() {
    window.removeEventListener("resize", this.Resize);
  },
  data() {
    return {
      Aplicacion: {
        Loading: true,
      },
    };
  },
  mounted() {
    this.ResolveApp();
  },
  methods: {
    ResolveApp() {
      setTimeout(() => {
        this.Aplicacion.Loading = false;
        setTimeout(() => {
          this.RefreshAOS();
        }, 375);
      }, 3500);
    },
    Refresh() {
      this.$root.$emit("SetHeader");
      if (window.innerWidth <= 800) {
        this.$root.$emit("HideHeader");
      }
      this.RefreshAOS();
    },
    RefreshAOS() {
      setTimeout(() => {
        AOS.refresh();
        console.log("AOS Restarted");
      }, 375);
    },
    Resize() {
      if (this.Aplicacion.Loading==false) {
        var Footer = document.getElementsByClassName("app__footer")[0];
        if (window.innerWidth <= 800) {
          Footer.classList.add("hidden");
        } else {
          Footer.classList.remove("hidden");
        }
      }
    },
  },
};
</script>

<style lang="scss">
.app {
  &__footer {
    &.hidden {
      display: none;
    }
  }
}
</style>
