<template>
  <div
    class="modal"
    :class="{
      active: Visible,
    }"
  >
    <div class="modal__content">
      <div class="modal__content__title">{{ $t(Content.Title) }}</div>
      <div class="modal__content__texts">
        <div
          v-for="(Text, TextIndex) in Content.Texts"
          :key="TextIndex"
          class="modal__content__texts__item"
        >
          {{ $t(Text) }}
        </div>
      </div>
    </div>
    <img
      @click="HideModal()"
      src="@/assets/Images/Global/Close.svg"
      alt=""
      class="modal__close"
    />
  </div>
</template>

<script>
export default {
  name: "Modal",
  data() {
    return {
      Visible: false,
      Content: "",
    };
  },
  mounted() {
    this.$root.$on("ShowModal", (Content) => {
      this.ShowModal(Content);
    });
    this.$root.$on("HideModal", () => {
      this.HideModal();
    });
  },
  methods: {
    ShowModal(Content) {
      document.documentElement.style.overflowY = "hidden";
      this.Content = Content;
      this.Visible = true;
    },
    HideModal() {
      document.documentElement.style.overflowY = "overlay";
      this.Visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  opacity: 0;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100dvw;
  height: 100dvh;
  background-color: rgba(30, 30, 30, 0.9);
  z-index: 1001;
  -webkit-transition: 0.35s ease-in-out opacity;
  -moz-transition: 0.35s ease-in-out opacity;
  -ms-transition: 0.35s ease-in-out opacity;
  -o-transition: 0.35s ease-in-out opacity;
  transition: 0.35s ease-in-out opacity;
  &__close {
    cursor: pointer;
    filter: invert(100%);
    position: absolute;
    right: 0;
    top: 0;
    width: 44px;
    height: 44px;
    padding: 10px;
    background: invert(rgba(30, 30, 30, 0.6));
  }
  &__content {
    width: 75%;
    height: 75%;
    opacity: 1;
    pointer-events: hidden;
    background: rgb(0, 0, 0, 0.9);
    overflow: auto;
    padding: 80px;
    color: white;
    &__title {
      font-size: 35px;
      font-weight: 600;
    }
    &__texts {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      font-size: 13px;
      font-weight: 400;
      line-height: 25px;
      margin-top: 45px;
      &__item {
        &:not(:first-child) {
          margin-top: 20px;
        }
      }
    }
  }
  &.active {
    opacity: 1;
    pointer-events: visible;
    -webkit-transition: 0.35s ease-in-out opacity;
    -moz-transition: 0.35s ease-in-out opacity;
    -ms-transition: 0.35s ease-in-out opacity;
    -o-transition: 0.35s ease-in-out opacity;
    transition: 0.35s ease-in-out opacity;
  }
  @media screen and (max-width: 800px) {
    &__content {
      width: 100%;
      height: 100%;
      padding: 20px;
    }
  }
}
</style>
