<template>
  <div class="scroller" @click="ScrollTo()">
    <img
      src="@/assets/Images/Global/ArrowG.svg"
      alt=""
      class="scroller__image"
    />
  </div>
</template>

<script>
export default {
  name: "Scroller",
  created() {
    window.addEventListener("scroll", this.SetScroller);
  },
  destroyed() {
    window.removeEventListener("scroll", this.SetScroller);
  },
  mounted() {
    this.SetScroller();
  },
  methods: {
    SetScroller() {
      var scroller = document.getElementsByClassName("scroller")[0];
      if (window.scrollY == 0) {
        scroller.classList.add("hidden");
      } else {
        scroller.classList.remove("hidden");
        if (((window.innerHeight + window.scrollY)+5) >= document.body.offsetHeight) {
          scroller.classList.add("bottom");
        } else {
          scroller.classList.remove("bottom");
        }
      }
    },
    ScrollTo() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.scroller {
  cursor: pointer;
  opacity: 1;
  pointer-events: visible;
  position: fixed;
  bottom: 15px;
  right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  -webkit-transition: 0.35s ease-in-out all;
  -moz-transition: 0.35s ease-in-out all;
  -ms-transition: 0.35s ease-in-out all;
  -o-transition: 0.35s ease-in-out all;
  transition: 0.35s ease-in-out all;
  &__image {
    filter: invert(60%);
    transform: rotate(180deg);
    height: 14px;
  }
  &:hover {
    bottom: 20px;
    -webkit-transition: 0.35s ease-in-out all;
    -moz-transition: 0.35s ease-in-out all;
    -ms-transition: 0.35s ease-in-out all;
    -o-transition: 0.35s ease-in-out all;
    transition: 0.35s ease-in-out all;
  }
  &.hidden {
    opacity: 0;
    pointer-events: none;
    -webkit-transition: 0.35s ease-in-out all;
    -moz-transition: 0.35s ease-in-out all;
    -ms-transition: 0.35s ease-in-out all;
    -o-transition: 0.35s ease-in-out all;
    transition: 0.35s ease-in-out all;
  }
  &.bottom {
    bottom: 65px;
    -webkit-transition: 0.35s ease-in-out all;
    -moz-transition: 0.35s ease-in-out all;
    -ms-transition: 0.35s ease-in-out all;
    -o-transition: 0.35s ease-in-out all;
    transition: 0.35s ease-in-out all;
    &:hover {
      bottom: 70px;
      -webkit-transition: 0.35s ease-in-out all;
      -moz-transition: 0.35s ease-in-out all;
      -ms-transition: 0.35s ease-in-out all;
      -o-transition: 0.35s ease-in-out all;
      transition: 0.35s ease-in-out all;
    }
  }
  @media screen and (max-width: 800px) {
    display: none;
  }
}
</style>
