<template>
  <div class="footer">
    <div class="footer__copyright">
      <div class="footer__copyright__logo">&#169;</div>
      <div class="footer__copyright__year">{{ new Date().getFullYear() }}</div>
      <div class="footer__copyright__company">3dr</div>
    </div>
    <div
      class="footer__rights langchange"
      @click="$root.$emit('ShowModal', Rights.Content)"
    >
      {{ $t(Rights.Name) }}
    </div>
    <div
      class="footer__grants langchange"
      @click="$root.$emit('ShowModal', Grants.Content)"
    >
      {{ $t(Grants.Name) }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      Rights: {
        Name: "Static.Footer.Rights.Name",
        Content: {
          Title: "Static.Footer.Rights.Content.Title",
          Texts: [
            "Static.Footer.Rights.Content.Texts[0]",
            "Static.Footer.Rights.Content.Texts[1]",
            "Static.Footer.Rights.Content.Texts[2]",
            "Static.Footer.Rights.Content.Texts[3]",
            "Static.Footer.Rights.Content.Texts[4]",
            "Static.Footer.Rights.Content.Texts[5]",
            "Static.Footer.Rights.Content.Texts[6]",
            "Static.Footer.Rights.Content.Texts[7]",
            "Static.Footer.Rights.Content.Texts[8]",
            "Static.Footer.Rights.Content.Texts[9]",
            "Static.Footer.Rights.Content.Texts[10]",
            "Static.Footer.Rights.Content.Texts[11]",
            "Static.Footer.Rights.Content.Texts[12]",
            "Static.Footer.Rights.Content.Texts[13]",
            "Static.Footer.Rights.Content.Texts[14]",
          ],
        },
      },
      Grants: {
        Name: "Static.Footer.Grants.Name",
        Content: {
          Title: "Static.Footer.Grants.Content.Title",
          Texts: ["Static.Footer.Grants.Content.Text"],
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  height: 45px;
  font-size: 12.5px;
  background-color: rgba(241, 241, 241, 0.6);
  padding: 0px 45px 0px 45px;
  &__copyright {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    &__logo {
    }
    &__year {
      margin-left: 3px;
    }
    &__company {
      font-weight: 600;
      margin-left: 10px;
    }
  }
  &__rights {
    cursor: pointer;
    margin-left: 45px;
  }
  &__grants {
    cursor: pointer;
    margin-left: 45px;
  }
  @media screen and (max-width: 800px) {
    opacity: 0;
    pointer-events: none;
  }
  @media screen and (max-width: 800px) {
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 65px;
    padding: 0px 20px 0px 20px;
    &__copyright {
      position: absolute;
      right: 45px;
    }
    &__rights {
      margin-left: revert;
    }
    &__grants {
      margin-left: revert;
    }
  }
  @media screen and (max-width: 330px) {
    height: 100px;
    &__copyright {
      position: revert;
      right: revert;
    }
  }
}
</style>
