import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/About",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "About" */ "../views/About.vue"),
  },
  {
    path: "/Contact",
    name: "Contact",
    // route level code-splitting
    // this generates a separate chunk (Contact.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Contact" */ "../views/Contact.vue"),
  },
  {
    path: "/Images/:Sector?",
    name: "Images",
    // route level code-splitting
    // this generates a separate chunk (Images.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Images" */ "../views/Products/Images.vue"),
  },
  {
    path: "/Selector",
    name: "Selector",
    // route level code-splitting
    // this generates a separate chunk (Selector.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "Selector" */ "../views/Products/Selector.vue"
      ),
  },
  {
    path: "/AugmentedReality",
    name: "AugmentedReality",
    // route level code-splitting
    // this generates a separate chunk (AugmentedReality.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "AugmentedReality" */ "../views/Products/AugmentedReality.vue"
      ),
  },
  {
    path: "/VirtualTour",
    name: "VirtualTour",
    // route level code-splitting
    // this generates a separate chunk (VirtualTour.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "VirtualTour" */ "../views/Products/VirtualTour.vue"
      ),
  },
  {
    path: "/Video",
    name: "Video",
    // route level code-splitting
    // this generates a separate chunk (Video.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Video" */ "../views/Products/Video.vue"),
  },
];

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
