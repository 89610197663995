<template>
  <div class="header">
    <router-link to="/" class="header__logos">
      <img
        v-for="(Logo, LogoIndex) in Data.Logos"
        :key="LogoIndex"
        :src="require('@/assets/Images/' + Logo)"
        alt=""
        class="header__logos__image"
      />
    </router-link>
    <div class="header__menu">
      <div class="header__menu__links">
        <div
          v-for="(Link, LinkIndex) in Data.Links"
          :key="LinkIndex"
          class="header__menu__links__item langchange"
        >
          <div
            v-if="LinkIndex == 1"
            class="header__menu__links__item__dropdown"
          >
            <div class="header__menu__links__item__dropdown__title">
              <div class="header__menu__links__item__dropdown__title__name">
                {{ $t(Link.Name) }}
              </div>
              <img
                src="@/assets/Images/Global/ArrowG.svg"
                alt=""
                class="header__menu__links__item__dropdown__title__arrow"
              />
            </div>
            <div class="header__menu__links__item__dropdown__items">
              <router-link
                v-for="(Link, LinkIndex) in Link.Items"
                :key="LinkIndex"
                class="header__menu__links__item__dropdown__items__link"
                :to="Link.URL"
                >{{ $t(Link.Name) }}</router-link
              >
            </div>
          </div>
          <router-link
            v-else
            class="header__menu__links__item__link"
            :to="Link.URL"
            >{{ $t(Link.Name) }}</router-link
          >
        </div>
      </div>
      <div class="header__menu__languages">
        <div
          v-for="(Language, LanguageIndex) in Data.Languages"
          :key="LanguageIndex"
          class="header__menu__languages__item"
          :class="{
            active: Language == $i18n.locale,
          }"
          @click="SetLanguage(Language)"
        >
          {{ Language }}
        </div>
      </div>
      <div class="header__menu__hamburguer" @click="Hamburguer = !Hamburguer">
        <div class="header__menu__hamburguer__bar"></div>
        <div class="header__menu__hamburguer__bar"></div>
        <div class="header__menu__hamburguer__bar"></div>
      </div>
      <Footer class="header__menu__footer" />
    </div>
  </div>
</template>

<script>
import i18n from "@/i18n";
import Footer from "@/components/Static/Footer.vue";

export default {
  name: "Header",
  components: {
    Footer,
  },
  data() {
    return {
      Hamburguer: false,
      Scroll: 0,
      Header: Element,
      Data: {
        Logos: ["Static/3d.png", "Static/r.png"],
        Links: [
          {
            Name: "Static.Header.Links[0].Name",
            URL: "/",
          },
          {
            Name: "Static.Header.Links[1].Name",
            Items: [
              {
                Name: "Static.Header.Links[1].Items[0].Name",
                URL: "Images",
              },
              {
                Name: "Static.Header.Links[1].Items[1].Name",
                URL: "Selector",
              },
              {
                Name: "Static.Header.Links[1].Items[2].Name",
                URL: "AugmentedReality",
              },
              {
                Name: "Static.Header.Links[1].Items[3].Name",
                URL: "VirtualTour",
              },
              {
                Name: "Static.Header.Links[1].Items[4].Name",
                URL: "Video",
              },
            ],
          },
          {
            Name: "Static.Header.Links[2].Name",
            URL: "About",
          },
          {
            Name: "Static.Header.Links[3].Name",
            URL: "Contact",
          },
        ],
        LanguageSel: "es",
        Languages: ["es", "en"],
      },
    };
  },
  watch: {
    Hamburguer: {
      handler() {
        document.getElementsByClassName("header")[0].classList.toggle("open");
        document
          .getElementsByClassName("header__menu__hamburguer")[0]
          .classList.toggle("open");
      },
      deep: true,
    },
  },
  created() {
    window.addEventListener("scroll", this.SetHeader);
    window.addEventListener("resize", this.SetHeader);
  },
  destroyed() {
    window.removeEventListener("scroll", this.SetHeader);
    window.removeEventListener("resize", this.SetHeader);
  },
  mounted() {
    this.SetDefaultLanguage();
    this.GetHeader();
    this.SetHeader();
    this.Emits();
  },
  methods: {
    SetDefaultLanguage() {
      this.SetLanguage(this.GetStoredLanguage());
    },
    SetLanguage(language) {
      this.UpdateCookie("3drlanguage", language);
      this.UpdateLanguageVisibility();
      setTimeout(() => {
        this.UpdateI18n(language);
      }, 350);
      this.UpdateVisualActiveClass(language);
    },
    GetStoredLanguage() {
      const name = "3drlanguage=";
      const cDecoded = decodeURIComponent(document.cookie);
      const cArr = cDecoded.split("; ");
      let res;
      cArr.forEach((val) => {
        if (val.indexOf(name) === 0) res = val.substring(name.length);
      });
      var language = "";
      if (res == null) {
        language = "en";
      } else {
        language = res;
      }
      return language;
    },
    UpdateCookie(cookie, value) {
      document.cookie = cookie + "=" + value + ";path='/'";
    },
    UpdateLanguageVisibility() {
      var elements = document.getElementsByTagName("*");
      for (let element of elements) {
        if (element.classList.contains("langchange")) {
          element.style.opacity = 0;
          setTimeout(() => {
            element.style.opacity = 1;
          }, 350);
        }
      }
    },
    UpdateI18n(language) {
      i18n.locale = language.toLowerCase();
    },
    UpdateVisualActiveClass(language) {
      var languages = document.getElementsByClassName(
        "header__menu__languages"
      )[0].children;
      for (let item of languages) {
        if (item.classList.contains("active")) {
          item.classList.remove("active");
        }
        if (item.innerHTML.includes(language)) {
          item.classList.add("active");
        }
      }
    },
    GetHeader() {
      this.Header = document.getElementsByClassName("header")[0];
    },
    SetHeader() {
      if (window.scrollY == 0) {
        this.Header.classList.add("large");
        if (this.$route.name == "Home") {
          this.Header.classList.add("transparent");
        } else {
          this.Header.classList.remove("transparent");
        }
      } else {
        this.Header.classList.remove("large");
        if (this.$route.name == "Home") {
          this.Header.classList.remove("transparent");
        }
        var st = window.pageYOffset || document.documentElement.scrollTop;
        if (st > this.Scroll) {
          this.Header.classList.add("hidden");
        } else if (st < this.Scroll) {
          this.Header.classList.remove("hidden");
        }
        this.Scroll = st <= 0 ? 0 : st;
      }
    },
    HideHeader() {
      document.getElementsByClassName("header")[0].classList.remove("open");
      document
        .getElementsByClassName("header__menu__hamburguer")[0]
        .classList.remove("open");
    },
    Emits() {
      this.$root.$on("SetHeader", () => {
        this.SetHeader();
      });
      this.$root.$on("HideHeader", () => {
        this.HideHeader();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  height: 75px;
  padding: 0px 45px 0px 45px;
  @media screen and (max-width: 800px) {
    padding: 0px 20px 0px 20px;
  }
  z-index: 1000;
  opacity: 1;
  pointer-events: visible;
  -webkit-transition: 0.35s ease-in-out all;
  -moz-transition: 0.35s ease-in-out all;
  -ms-transition: 0.35s ease-in-out all;
  -o-transition: 0.35s ease-in-out all;
  transition: 0.35s ease-in-out all;
  &__logos {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    &__image {
      height: 28px;
      &:first-child {
        filter: invert(0%);
        -webkit-transition: 0.35s ease-in-out all;
        -moz-transition: 0.35s ease-in-out all;
        -ms-transition: 0.35s ease-in-out all;
        -o-transition: 0.35s ease-in-out all;
        transition: 0.35s ease-in-out all;
      }
      &:last-child {
        height: 28px;
        margin-left: 4px;
        margin-top: -1px;
      }
    }
  }
  &__menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    &__links {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      align-content: center;
      margin-right: 60px;
      font-size: 14px;
      &__item {
        &:first-child {
          display: none;
        }
        &__link {
          opacity: 1;
          color: black;
          text-decoration: none;
          -webkit-transition: 0.35s ease-in-out all;
          -moz-transition: 0.35s ease-in-out all;
          -ms-transition: 0.35s ease-in-out all;
          -o-transition: 0.35s ease-in-out all;
          transition: 0.35s ease-in-out all;
          &:hover {
            opacity: 0.45;
            -webkit-transition: 0.35s ease-in-out all;
            -moz-transition: 0.35s ease-in-out all;
            -ms-transition: 0.35s ease-in-out all;
            -o-transition: 0.35s ease-in-out all;
            transition: 0.35s ease-in-out all;
          }
        }
        &__dropdown {
          cursor: pointer;
          position: relative;
          &__title {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            align-content: center;
            &__name {
              color: black;
              -webkit-transition: 0.35s ease-in-out all;
              -moz-transition: 0.35s ease-in-out all;
              -ms-transition: 0.35s ease-in-out all;
              -o-transition: 0.35s ease-in-out all;
              transition: 0.35s ease-in-out all;
            }
            &__arrow {
              filter: invert(0%);
              height: 7px;
              margin-left: 4px;
              -webkit-transition: 0.35s ease-in-out all;
              -moz-transition: 0.35s ease-in-out all;
              -ms-transition: 0.35s ease-in-out all;
              -o-transition: 0.35s ease-in-out all;
              transition: 0.35s ease-in-out all;
            }
          }
          &__items {
            opacity: 0;
            pointer-events: none;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            align-content: center;
            position: fixed;
            padding: 15px;
            padding-top: 20px;
            background-color: rgba($color: #ffffff, $alpha: 1);
            box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
            -webkit-transition: 0.35s ease-in-out all;
            -moz-transition: 0.35s ease-in-out all;
            -ms-transition: 0.35s ease-in-out all;
            -o-transition: 0.35s ease-in-out all;
            transition: 0.35s ease-in-out all;
            &__link {
              opacity: 1;
              color: black;
              font-size: 13px;
              text-decoration: none;
              -webkit-transition: 0.35s ease-in-out all;
              -moz-transition: 0.35s ease-in-out all;
              -ms-transition: 0.35s ease-in-out all;
              -o-transition: 0.35s ease-in-out all;
              transition: 0.35s ease-in-out all;
              &:hover {
                opacity: 0.45;
                -webkit-transition: 0.35s ease-in-out all;
                -moz-transition: 0.35s ease-in-out all;
                -ms-transition: 0.35s ease-in-out all;
                -o-transition: 0.35s ease-in-out all;
                transition: 0.35s ease-in-out all;
              }
              &:not(:first-child) {
                margin-top: 15px;
              }
            }
            &.open {
              opacity: 1;
              pointer-events: visible;
              -webkit-transition: 0.35s ease-in-out all;
              -moz-transition: 0.35s ease-in-out all;
              -ms-transition: 0.35s ease-in-out all;
              -o-transition: 0.35s ease-in-out all;
              transition: 0.35s ease-in-out all;
            }
            &:hover {
              opacity: 1;
              pointer-events: visible;
            }
          }
          &:hover {
            & .header__menu__links__item__dropdown__items {
              opacity: 1;
              pointer-events: visible;
            }
          }
        }
        &:not(:first-child) {
          margin-left: 35px;
        }
      }
    }
    &__languages {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      align-content: center;
      font-size: 14px;
      &__item {
        cursor: pointer;
        opacity: 0.35;
        line-height: 1.3em;
        color: black;
        text-transform: uppercase;
        -webkit-transition: 0.35s ease-in-out all;
        -moz-transition: 0.35s ease-in-out all;
        -ms-transition: 0.35s ease-in-out all;
        -o-transition: 0.35s ease-in-out all;
        transition: 0.35s ease-in-out all;
        &:not(:first-child) {
          margin-left: 15px;
        }
        &:hover {
          opacity: 0.75;
          -webkit-transition: 0.35s ease-in-out all;
          -moz-transition: 0.35s ease-in-out all;
          -ms-transition: 0.35s ease-in-out all;
          -o-transition: 0.35s ease-in-out all;
          transition: 0.35s ease-in-out all;
        }
        &::after {
          content: "";
          width: 0px;
          height: 2px;
          display: block;
          background: transparent;
          -webkit-transition: 0.35s ease-in-out all;
          -moz-transition: 0.35s ease-in-out all;
          -ms-transition: 0.35s ease-in-out all;
          -o-transition: 0.35s ease-in-out all;
          transition: 0.35s ease-in-out all;
        }
        &.active {
          opacity: 1;
          -webkit-transition: 0.35s ease-in-out all;
          -moz-transition: 0.35s ease-in-out all;
          -ms-transition: 0.35s ease-in-out all;
          -o-transition: 0.35s ease-in-out all;
          transition: 0.35s ease-in-out all;
          &::after {
            width: 100%;
            background: black;
            -webkit-transition: 0.35s ease-in-out all;
            -moz-transition: 0.35s ease-in-out all;
            -ms-transition: 0.35s ease-in-out all;
            -o-transition: 0.35s ease-in-out all;
            transition: 0.35s ease-in-out all;
          }
        }
      }
    }
    &__hamburguer {
      position: absolute;
      right: 20px;
      top: 18.7px;
      display: none;
      cursor: pointer;
      &__bar {
        width: 25px;
        height: 2px;
        background-color: #333;
        margin: 4px 0;
        -webkit-transition: 0.35s ease-in-out all;
        -moz-transition: 0.35s ease-in-out all;
        -ms-transition: 0.35s ease-in-out all;
        -o-transition: 0.35s ease-in-out all;
        transition: 0.35s ease-in-out all;
      }
      &.open {
        & .header__menu__hamburguer__bar {
          &:first-child {
            transform: translate(0, 7px) rotate(-45deg);
            -webkit-transition: 0.35s ease-in-out all;
            -moz-transition: 0.35s ease-in-out all;
            -ms-transition: 0.35s ease-in-out all;
            -o-transition: 0.35s ease-in-out all;
            transition: 0.35s ease-in-out all;
          }
          &:nth-child(2) {
            opacity: 0;
            -webkit-transition: 0.35s ease-in-out all;
            -moz-transition: 0.35s ease-in-out all;
            -ms-transition: 0.35s ease-in-out all;
            -o-transition: 0.35s ease-in-out all;
            transition: 0.35s ease-in-out all;
          }
          &:last-child {
            transform: translate(0, -5px) rotate(45deg);
            -webkit-transition: 0.35s ease-in-out all;
            -moz-transition: 0.35s ease-in-out all;
            -ms-transition: 0.35s ease-in-out all;
            -o-transition: 0.35s ease-in-out all;
            transition: 0.35s ease-in-out all;
          }
        }
      }
    }
    &__footer {
      display: none;
      opacity: 0;
      pointer-events: none;
      -webkit-transition: 0.35s ease-in-out all;
      -moz-transition: 0.35s ease-in-out all;
      -ms-transition: 0.35s ease-in-out all;
      -o-transition: 0.35s ease-in-out all;
      transition: 0.35s ease-in-out all;
    }
  }
  &.hidden {
    opacity: 0;
    pointer-events: none;
    -webkit-transition: 0.35s ease-in-out all;
    -moz-transition: 0.35s ease-in-out all;
    -ms-transition: 0.35s ease-in-out all;
    -o-transition: 0.35s ease-in-out all;
    transition: 0.35s ease-in-out all;
  }
  @media screen and (min-width: 800px) {
    &.transparent {
      background-color: transparent;
      padding: 45px 45px 45px 45px;
      @media screen and (max-width: 800px) {
        padding: 20px 20px 20px 20px;
      }
      -webkit-transition: 0.35s ease-in-out all;
      -moz-transition: 0.35s ease-in-out all;
      -ms-transition: 0.35s ease-in-out all;
      -o-transition: 0.35s ease-in-out all;
      transition: 0.35s ease-in-out all;
      & .header {
        &__logos {
          &__image {
            &:first-child {
              filter: invert(100%);
              -webkit-transition: 0.35s ease-in-out all;
              -moz-transition: 0.35s ease-in-out all;
              -ms-transition: 0.35s ease-in-out all;
              -o-transition: 0.35s ease-in-out all;
              transition: 0.35s ease-in-out all;
            }
          }
        }
        &__menu {
          &__links {
            &__item {
              &__link {
                color: white;
                text-decoration: none;
                -webkit-transition: 0.35s ease-in-out all;
                -moz-transition: 0.35s ease-in-out all;
                -ms-transition: 0.35s ease-in-out all;
                -o-transition: 0.35s ease-in-out all;
                transition: 0.35s ease-in-out all;
              }
              &__dropdown {
                &__title {
                  &__name {
                    color: white;
                    -webkit-transition: 0.35s ease-in-out all;
                    -moz-transition: 0.35s ease-in-out all;
                    -ms-transition: 0.35s ease-in-out all;
                    -o-transition: 0.35s ease-in-out all;
                    transition: 0.35s ease-in-out all;
                  }
                  &__arrow {
                    filter: invert(100%);
                    -webkit-transition: 0.35s ease-in-out all;
                    -moz-transition: 0.35s ease-in-out all;
                    -ms-transition: 0.35s ease-in-out all;
                    -o-transition: 0.35s ease-in-out all;
                    transition: 0.35s ease-in-out all;
                  }
                }
                &__items {
                  background-color: rgba($color: #ffffff, $alpha: 0);
                  box-shadow: rgba(17, 17, 26, 0) 0px 1px 0px;
                  -webkit-transition: 0.35s ease-in-out all;
                  -moz-transition: 0.35s ease-in-out all;
                  -ms-transition: 0.35s ease-in-out all;
                  -o-transition: 0.35s ease-in-out all;
                  transition: 0.35s ease-in-out all;
                  &__link {
                    color: white;
                    -webkit-transition: 0.35s ease-in-out all;
                    -moz-transition: 0.35s ease-in-out all;
                    -ms-transition: 0.35s ease-in-out all;
                    -o-transition: 0.35s ease-in-out all;
                    transition: 0.35s ease-in-out all;
                  }
                }
              }
            }
          }
          &__languages {
            &__item {
              color: white;
              -webkit-transition: 0.35s ease-in-out all;
              -moz-transition: 0.35s ease-in-out all;
              -ms-transition: 0.35s ease-in-out all;
              -o-transition: 0.35s ease-in-out all;
              transition: 0.35s ease-in-out all;
              &.active {
                &::after {
                  background: white;
                  -webkit-transition: 0.35s ease-in-out all;
                  -moz-transition: 0.35s ease-in-out all;
                  -ms-transition: 0.35s ease-in-out all;
                  -o-transition: 0.35s ease-in-out all;
                  transition: 0.35s ease-in-out all;
                }
              }
            }
          }
        }
      }
    }
    &.large {
      height: 140px;
      -webkit-transition: 0.35s ease-in-out all;
      -moz-transition: 0.35s ease-in-out all;
      -ms-transition: 0.35s ease-in-out all;
      -o-transition: 0.35s ease-in-out all;
      transition: 0.35s ease-in-out all;
      @media screen and (max-width: 800px) {
        height: 75px;
      }
    }
  }
  @media screen and (max-width: 800px) {
    opacity: 1;
    padding: 0px 0px 0px 0px;
    height: 60px;
    -webkit-transition: 0.35s ease-in-out all;
    -moz-transition: 0.35s ease-in-out all;
    -ms-transition: 0.35s ease-in-out all;
    -o-transition: 0.35s ease-in-out all;
    transition: 0.35s ease-in-out all;
    &__logos {
      position: absolute;
      left: 20px;
      top: 15px;
      &__image {
        &:first-child {
          height: 20px;
        }
        &:last-child {
          height: 24px;
        }
      }
    }
    &__menu {
      width: 100%;
      height: 100%;
      text-align: center;
      &__links {
        flex-direction: column;
        opacity: 0;
        pointer-events: none;
        margin-right: revert;
        width: 100%;
        height: 100%;
        justify-content: flex-start;
        padding-top: 60px;
        -webkit-transition: 0.35s ease-in-out all;
        -moz-transition: 0.35s ease-in-out all;
        -ms-transition: 0.35s ease-in-out all;
        -o-transition: 0.35s ease-in-out all;
        transition: 0.35s ease-in-out all;
        &__item {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          &:first-child {
            display: none;
          }
          &:not(:first-child) {
            margin-left: revert;
          }
          &:not(:nth-child(2)) {
            height: 50px;
          }
          &:last-child {
            border: 1px solid black;
          }
          &:nth-child(3) {
            border-left: 1px solid black;
            border-right: 1px solid black;
          }
          &__dropdown {
            width: 100%;
            &__title {
              display: none;
            }
            &__arrow {
              display: none;
            }
            &__items {
              width: 100%;
              opacity: 1;
              position: revert;
              pointer-events: revert;
              display: flex;
              flex-wrap: wrap;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              align-content: center;
              padding: revert;
              padding-top: revert;
              background-color: revert;
              box-shadow: revert;
              -webkit-transition: 0.35s ease-in-out all;
              -moz-transition: 0.35s ease-in-out all;
              -ms-transition: 0.35s ease-in-out all;
              -o-transition: 0.35s ease-in-out all;
              transition: 0.35s ease-in-out all;
              &__link {
                font-size: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: calc(100%);
                height: 50px;
                margin-top: revert !important;
                border: 0.5px solid black;
                &:not(:first-child) {
                  border-top: none;
                }
              }
            }
          }
        }
      }
      &__languages {
        position: absolute;
        top: 20px;
        right: 70px;
      }
      &__hamburguer {
        display: inline-block;
      }
      &__footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        opacity: 0;
        pointer-events: none;
        -webkit-transition: 0.35s ease-in-out all;
        -moz-transition: 0.35s ease-in-out all;
        -ms-transition: 0.35s ease-in-out all;
        -o-transition: 0.35s ease-in-out all;
        transition: 0.35s ease-in-out all;
      }
    }
    &.open {
      height: 475px;
      -webkit-transition: 0.35s ease-in-out all;
      -moz-transition: 0.35s ease-in-out all;
      -ms-transition: 0.35s ease-in-out all;
      -o-transition: 0.35s ease-in-out all;
      transition: 0.35s ease-in-out all;
      @media screen and (max-width: 330px) {
        height: 510px;
        -webkit-transition: 0.35s ease-in-out all;
        -moz-transition: 0.35s ease-in-out all;
        -ms-transition: 0.35s ease-in-out all;
        -o-transition: 0.35s ease-in-out all;
        transition: 0.35s ease-in-out all;
      }
      &.hidden {
        opacity: 1 !important;
      }
      & .header {
        &__menu {
          &__links {
            opacity: 1;
            pointer-events: visible;
            -webkit-transition: 0.35s ease-in-out all;
            -moz-transition: 0.35s ease-in-out all;
            -ms-transition: 0.35s ease-in-out all;
            -o-transition: 0.35s ease-in-out all;
            transition: 0.35s ease-in-out all;
          }
          &__footer {
            opacity: 1;
            pointer-events: visible;
            -webkit-transition: 0.35s ease-in-out all;
            -moz-transition: 0.35s ease-in-out all;
            -ms-transition: 0.35s ease-in-out all;
            -o-transition: 0.35s ease-in-out all;
            transition: 0.35s ease-in-out all;
          }
        }
      }
    }
  }
  @media screen and (max-width: 350px) {
    &__logos {
      left: 10px;
      &__image {
        &:first-child {
          height: 18px;
          -webkit-transition: 0.35s ease-in-out all;
          -moz-transition: 0.35s ease-in-out all;
          -ms-transition: 0.35s ease-in-out all;
          -o-transition: 0.35s ease-in-out all;
          transition: 0.35s ease-in-out all;
        }
        &:last-child {
          height: 21px;
          -webkit-transition: 0.35s ease-in-out all;
          -moz-transition: 0.35s ease-in-out all;
          -ms-transition: 0.35s ease-in-out all;
          -o-transition: 0.35s ease-in-out all;
          transition: 0.35s ease-in-out all;
        }
      }
    }
    &__menu {
      &__languages {
        right: 60px;
        &__item {
          &:not(:first-child) {
            margin-left: 10px;
          }
        }
      }
      &__hamburguer {
        right: 10px;
        &__bar {
          width: 20px;
        }
      }
    }
  }
}
</style>
